import React from "react";
import BlockContent from "./block-content";
import { Link } from "gatsby";

import * as styles from "../styles/newsandevents.module.css";

function NewsItem(props) {
  return (
    <article
      className={`news-article ${styles.newsArticle}`}
      key={props.newsItem._id}
    >
      <h3>{props.newsItem.title}</h3>
      <BlockContent blocks={props.newsItem._rawBody || []} />
      {props.newsItem.callToAction !== null &&
      props.newsItem.callToAction.href &&
      props.newsItem.callToAction.linkText ? (
        <Link className="action" to={props.newsItem.callToAction.href}>
          {props.newsItem.callToAction.linkText}
        </Link>
      ) : null}
    </article>
  );
}

export default NewsItem;

import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import BlockContent from "../components/block-content.js";
import NewsItem from "../components/news-item.js";
import Layout from "../components/layout.js";

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query IndexPage {
      allSanityNewsItem(sort: { fields: _createdAt, order: DESC }, limit: 1) {
        nodes {
          _id
          title
          _rawBody
          callToAction {
            href
            linkText
          }
        }
      }
      sanityPage(_id: { eq: "70bb7028-bb2a-496f-a528-0ef3d10d6554" }) {
        id
        title
        _rawIntroSentence
        _rawSurroundAfter
        callToAction {
          href
          linkText
        }
      }
      allSanityGroupEvent(sort: { fields: date, order: ASC }) {
        nodes {
          _id
          title
          date(formatString: "D MMMM YYYY")
          speakers {
            _id
            personTitle
            forename
            surname
          }
          href
        }
      }
    }
  `);

  const newsItems = data.allSanityNewsItem.nodes;
  const eventsPage = data.sanityPage;
  const events = data.allSanityGroupEvent.nodes;
  // find day after event
  events.forEach(function (event) {
    const eventDate = new Date(event.date);
    const eventOver = new Date(eventDate);
    eventOver.setDate(eventDate.getDate() + 1);
    event.nextDay = Number(eventOver);
  });

  // display events in the past if there are no events scheduled in the future
  const futureEvents = events.filter(
    (event) => new Date(event.nextDay) >= new Date()
  );
  const pastEvents = events.filter(
    (event) => new Date(event.nextDay) < new Date()
  );
  let eventsToDisplay = [];
  futureEvents.length
    ? (eventsToDisplay = futureEvents.slice(0, 2))
    : (eventsToDisplay = pastEvents.slice(-3).reverse());

  return (
    <Layout title="Latest news">
      {newsItems.map((i) => (
        <NewsItem newsItem={i} />
      ))}

      <p>
        <Link to="news/">More news</Link>
      </p>
      <div class="heading-container">
        {futureEvents.length ? (
          <h2>{eventsPage.title}</h2>
        ) : (
          <h2>Recent events</h2>
        )}
        <div class="heading-line"></div>
      </div>
      {futureEvents.length ? (
        <BlockContent blocks={eventsPage._rawIntroSentence || []} />
      ) : (
        ""
      )}
      <ul>
        {eventsToDisplay.map((event) => (
          <li key={event._id}>
            <time>{event.date}</time> -{" "}
            <strong>
              {event.href ? (
                <a href={event.href}>{event.title}</a>
              ) : (
                event.title
              )}
            </strong>{" "}
            -
            <span class="speakers">
              {event.speakers.map((speaker) => (
                <span className="speaker" key={speaker._id}>
                  {speaker.personTitle} {speaker.forename} {speaker.surname}
                </span>
              ))}
            </span>
          </li>
        ))}
      </ul>
      {/* link to past events if there are no events scheduled in future */}
      {futureEvents.length ? (
        <Link to="events">More events</Link>
      ) : (
        <Link to="past-events">More events</Link>
      )}
      {!futureEvents.length ? (
        <BlockContent blocks={eventsPage._rawIntroSentence || []} />
      ) : (
        ""
      )}
      <BlockContent blocks={eventsPage._rawSurroundAfter || []} />
      <Link class="action" to={eventsPage.callToAction.href}>
        {eventsPage.callToAction.linkText}
      </Link>
    </Layout>
  );
}
